import React from "react";

const SomeText: React.FC = () => {
  const styles = {
    main: {
      // width: "100%",
      margin: "auto",
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: 5,
      fontStyle: "italic" as "italic",
      // fontSize: "14pt",
    },
  };

  return (
    <div style={styles.main} className="text">
      <p>C’est le moment de la communion, tout le monde est le bienvenu.</p>
      <p>
        Approchez-vous pour recevoir Dieu dans votre cœur. Il y a deux manières
        de faire&nbsp;:
      </p>
      <ul>
        <li>
          <strong>Soit par la bénédiction&nbsp;:</strong> <br />
          il suffit de croiser les bras sur la poitrine, une main sur chaque
          épaule.
        </li>
        <li>
          <strong>Soit par la communion à l’hostie consacrée&nbsp;: </strong>
          pour ceux d’entre-nous qui sont croyants, baptisés et qui ont fait
          leur première communion.
        </li>
      </ul>
    </div>
  );
};

export default SomeText;
