import React from "react";
import Chant from "../assets/components/chant";
import COLORS from "../assets/colors";
import SomeText from "../assets/components/someText";
import TexteEnvoi from "../assets/components/texteEnvoi";

const Envoi: React.FC<{ messe: any; active: any }> = ({ messe, active }) => {
  return (
    <div
      style={{
        // height: "200vh",
        // paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      {/* <h1>Messe du premier dimanche de l'Avent</h1>
            <h2>30 novembre 2024 - S. André</h2> */}
      {/* <Chant glow={true} color={COLORS.red} /> */}

      {/* <SomeText /> */}
      {/* <Chant glow={false} color={COLORS.red} /> */}

      <TexteEnvoi />
      <Chant
        glow={active[0].moment === "envoi" ? true : false}
        color={COLORS.red}
        couplets={messe.sortie.couplets}
        refrain={messe.sortie.refrain}
        titre={messe.sortie.titre}
      />

      <Chant
        glow={false}
        color={COLORS.red}
        couplets={messe.annonces.couplets}
        refrain={messe.annonces.refrain}
        titre={messe.annonces.titre}
      />
    </div>
  );
};

export default Envoi;
