import React from "react";

const TexteEnvoi: React.FC = () => {
  const styles = {
    main: {
      // width: "100%",
      margin: "auto",
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: 5,
      fontStyle: "italic" as "italic",
      marginTop: 30,
      // fontSize: "14pt",
    },
  };

  return (
    <div style={styles.main} className="text">
      <p>
        C’est maintenant le temps de l'envoi, le temps le plus long de la messe.
      </p>
      <p>
        Il ne dure physiquement que quelques minutes mais en vérité, il dure
        jusqu'à la prochaine messe.
      </p>
      <p>
        Le prêtre va maintenant <strong>tous</strong> nous envoyer en mission
        pour témoigner, aller porter l'Évangile autour de nous, par notre parole
        mais aussi par notre attitude et par nos actes.
      </p>
    </div>
  );
};

export default TexteEnvoi;
