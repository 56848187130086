// import React, { useEffect, useState } from "react";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { ReactComponent as Book } from "../assets/svg/book-alt.svg";
// import { ReactComponent as BookFill } from "../assets/svg/book-alt-fill.svg";
// import { ReactComponent as House } from "../assets/svg/home.svg";
// import { ReactComponent as HouseFill } from "../assets/svg/home-fill.svg";
// import { ReactComponent as Cross } from "../assets/svg/cross.svg";
// import { ReactComponent as CrossFill } from "../assets/svg/cross-fill.svg";
// import { ReactComponent as Fire } from "../assets/svg/fire.svg";
// import { ReactComponent as FireFill } from "../assets/svg/fire-fill.svg";
// import COLORS from "../assets/colors";
// import { supabase } from "../supabaseClient";

import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Book } from "../assets/svg/book-alt.svg";
import { ReactComponent as BookFill } from "../assets/svg/book-alt-fill.svg";
import { ReactComponent as House } from "../assets/svg/home.svg";
import { ReactComponent as HouseFill } from "../assets/svg/home-fill.svg";
import { ReactComponent as Cross } from "../assets/svg/cross.svg";
import { ReactComponent as CrossFill } from "../assets/svg/cross-fill.svg";
import { ReactComponent as Fire } from "../assets/svg/fire.svg";
import { ReactComponent as FireFill } from "../assets/svg/fire-fill.svg";
import COLORS from "../assets/colors";
import { supabase } from "../supabaseClient";

const Navbar = forwardRef((props, ref) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);
  const [path, setPath] = useState<string>("/");
  const [color, setColor] = useState<string>(COLORS.blue);

  useImperativeHandle(ref, () => ({
    setPath,
  }));

  useEffect(() => {
    setCurrentPath(location.pathname);
    console.log(location.pathname);
    switch (location.pathname) {
      case "/":
        setColor(COLORS.blue);
        break;
      case "/parole":
        setColor(COLORS.green);
        break;
      case "/eucharistie":
        setColor(COLORS.yellow);
        break;
      case "/envoi":
        setColor(COLORS.red);
        break;
      default:
        setColor(COLORS.blue);
    }
  }, [location]);

  const styles = {
    backgroundGlow: {
      position: "absolute" as "absolute",
      width: 40,
      height: 40,
      borderRadius: "50%",
      top: "-10%",
      transform: "translateY(50%)",
      backgroundColor: color,
      zIndex: -1,
      filter: "blur(22px)",
    },
  };
  const navigate = useNavigate();

  useEffect(() => {
    const redirectionChannel = supabase.channel("redirection");
    redirectionChannel.on("broadcast", { event: "redirection" }, (payload) => {
      console.log(
        "Received redirection message:",
        payload,
        payload.payload.link
      );
      navigate(payload.payload.link);
    });

    redirectionChannel.subscribe();

    return () => {
      supabase.removeChannel(redirectionChannel);
    };
  }, []);

  useEffect(() => {
    navigate(path);
  }, [path]);

  return (
    <>
      <nav
        className="navbar"
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 100,
          backdropFilter: "blur(10px)",
        }}
      >
        <div
          style={{
            paddingRight: 20,
            paddingLeft: 20,
            display: "flex",
            justifyContent: "space-between",
            width: "calc(100% - 40px)",
          }}
        >
          <NavLink
            to="/"
            style={{
              textDecoration: "none",
              userSelect: "none",
              outline: "none",
            }}
            className={"navlink"}
          >
            <div
              style={{
                width: 70,
                height: 70,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/" ? (
                <House
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <HouseFill
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -32 }}>Accueil</p>
              {currentPath === "/" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/parole"
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 70,
                height: 70,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/parole" ? (
                <BookFill
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Book
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -32 }}>Parole</p>
              {currentPath === "/parole" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/eucharistie"
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 70,
                height: 70,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/eucharistie" ? (
                <CrossFill
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Cross
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -32 }}>Eucharistie</p>
              {currentPath === "/eucharistie" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
          <NavLink
            to="/envoi"
            style={{ textDecoration: "none" }}
            className={"navlink"}
          >
            <div
              style={{
                width: 70,
                height: 70,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {currentPath === "/envoi" ? (
                <FireFill
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              ) : (
                <Fire
                  height={25}
                  width={25}
                  fill="white"
                  style={{ position: "absolute", padding: 15 }}
                  className="icon"
                />
              )}

              <p style={{ position: "relative", bottom: -32 }}>Envoi</p>
              {currentPath === "/envoi" ? (
                <div
                  className="backgroundGlow"
                  style={styles.backgroundGlow}
                ></div>
              ) : null}
            </div>
          </NavLink>
        </div>
      </nav>
    </>
  );
});

export default Navbar;
